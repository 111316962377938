

  /* Desktop styles */
.grid-container {
  display: flex;
  justify-content: space-between;
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.grid-item img {
  max-width: 300px;
  border-radius: 10%;
  margin: 20px;
  display: block;
  top: 50%;
  bottom: 50%;
}

.grid-item h3,
.grid-item p {
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  background-color: #00c49a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #008a6f;
}



.p-div {
  min-height: 100px;
}

/* Mobile styles */
@media (max-width: 767px) {
  .grid-container {
    flex-direction: column;
  }

  .grid-item {
    margin: 10px 0;
  }

  .grid-item img {
    max-width: 90%;
    border-radius: 10%;
    margin: 20px;
  }
}