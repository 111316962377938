/* Desktop styles */
.image {
  width: 100%;
  max-width: 300px; /* Adjust the maximum width as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensures proper spacing and alignment */
  border-radius: 20px; /* Adds rounded corners to the image */
  margin: 20px;
  margin-right: 30px;
  border-radius: 50%;
}

#about {
  padding: 50px;
  display: flex;
  align-items: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  display: flex;
  align-items: center;
}

.text {
  flex: 2;
  margin-left: 20px;
}

h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}
.home__img {
  background: url(../images/img6016-300x300-3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(0 196 154 / 50%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.btn {
  display: inline-block;
  background-color: #00c49a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #008a6f;
}

/* Mobile styles */
@media (max-width: 767px) {
  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .image {
    margin: 20px auto 0;
  }

  .text {
    margin: 20px 0 0;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
}
