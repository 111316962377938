@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



/* Reset default styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #333;
}

html {
  scroll-behavior: smooth;
}

/* Custom CSS for the progress bar */
.progress-bar {
  transition: width 0.3s ease-in-out;
}




/* CV section styles */
#cv {
  padding: 50px;
  background-color: #f5f5f5;
}



.content {
  display: flex;
  align-items: center;
}


.text {
  flex: 2;
}

h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background-color: #00c49a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: none;
  box-shadow: none;
  outline: none;
}

.btn:hover {
  background-color: #008a6f;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}




/* Projects section styles */
#projects {
  
  background-color: #fff;
}



.footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.impressum {
  font-size: 14px;
  color: #888;
}






