.grid-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.grid-inner-container {
  flex: 0 0 auto;
  display: flex;
}


@media (max-width: 640px) {
  .grid-inner-container {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .grid-box {
    flex: 0 0 100%;
    max-width: 80%;
  }
  
  .grid-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Change to normal */
    display: -webkit-box; /* Add vendor prefixes */
    -webkit-line-clamp: 10; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
  }
}
